<template>
    <div class="p-2">

        <!-- USER HEADER INFO -->
        <div class="d-flex align-items-center" style="gap:10px 20px">
            <div>
                <img :src="user.user_picture ? user.user_picture : default_img"
                    class="rounded-circle" style="height:80px;width:80px;border:2px solid white" />
            </div>
            <div v-if="user">
                <h3 class="text-white h4 mb-0">{{user.user_name}}</h3>
                <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{ user.user_phone }}</h3>
                <h3 class="text-white h4 mb-0">{{user.user_location}}</h3>
            </div>
            <div class="ml-auto">
                <router-link to="/profile/form">
                    <i class="text-white bx bx-edit" style="font-size: 28px;cursor: pointer"></i>
                </router-link>
            </div>
        </div>
        <!-- END USER HEADER INFO -->

        <!-- WALLET -->
        <!-- <div class="my-2 rounded bg-white p-1 w-100 d-flex align-items-center" style="gap:10px 20px">
            <i class="bx bxs-wallet" style="color:#1B628C;font-size: 28px;"></i>
            <div>
                <div style="color:#1B628C" class="font-weight-bold">Your Balance</div>
                <div class="d-flex align-items-center font-weight-bold" style="color:#1B628C">
                    <i class="bx bx-dollar"></i> 10.000,00
                </div>
            </div>
            <div class="ml-auto font-weight-bolder" style="color:#1B628C">
                VISA
            </div>
        </div> -->
        <!-- END WALLET -->

        <hr style="border-color: #FFAD32;" />
        
        <div class="d-flex align-items-center justify-content-center flex-wrap mt-2" style="gap:10px 10px">
            <router-link to="/walker/list" class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #FFAD32;width: 60px;height:60px;">
                    <img src="@/static/img_assets/walker.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Pet Sitting</div>
            </router-link>
            <router-link to="/training" class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #FFAD32;width: 60px;height:60px;">
                    <img src="@/static/img_assets/trainer.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Training</div>
            </router-link>
        </div>

        <hr style="border-color: #FFAD32;" />

        <!-- PETS -->
        <div class="position-relative mt-3 mb-2">
            <h1 class="text-white h3 text-center">Your Pets</h1>
            <router-link to="/profile/form-pet" class="position-absolute" style="right:0;top:0px;">
                <i class=" bx bx-plus font-weight-bolder"
                    style="color:#FFAD32;font-size: 22px;cursor: pointer;"></i>
            </router-link>
        </div>

        <hr class=""/>
        <div class="position-relative bg-white rounded py-1" v-if="pets.length < 1">
            <img src="@/static/undraw_Playful_cat_re_ac9g-removebg-preview.png" class="w-100" />
            <h1 class="text-dark h4 text-center">No Pet Available</h1>
        </div>

        <div :class="`d-flex align-items-center bg-white p-1 ${idx != 0 ? 'mt-1' : ''}`" style="gap:10px 20px;border: 2px solid #FFAD32;border-radius: 10px;"
            v-for="(item,idx) in pets">
            <div>
                <img :src="item.pet_picture"
                    class="rounded-circle" style="height:80px;width:80px" />
            </div>
            <div>
                <h3 class="text-dark h4 mb-0">{{item.pet_name}}</h3>
                <h3 class="text-dark h4 " style="margin: .25em 0 !important;">{{item.pet_breed}}</h3>
                <h3 class="text-dark h4 mb-0">{{ item.pet_age }} Months, {{item.pet_gender}}</h3>
            </div>
            <div class="ml-auto">
                <b-nav-item-dropdown class="dropdown-pet btn btn-flat-primary btn-sm" right>
                    <template #button-content>
                        <i class="bx bx-dots-vertical-rounded" style="font-size: 28px;cursor: pointer;color:#FFAD32"></i>
                    </template>
                    <b-dropdown-item style="border-bottom: 1px solid #FFAD32;">
                        <span class="text-center w-100" @click="editPet(item.pet_id)" style="color:#1F9BCE">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item style="">
                        <span class="text-center w-100" @click="deletePet(item.pet_id)" style="color:#1F9BCE">Delete</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item style="border-bottom: 1px solid #FFAD32;" @click="walkerRegis(item.pet_id)"> 
                        <span class="text-center w-100" style="color:#1F9BCE">Walker Registration</span>
                    </b-dropdown-item> -->
                    <!-- <b-dropdown-item style="border-bottom: 1px solid #FFAD32;" >
                        <span class="text-center w-100" style="color:#1F9BCE">Pet Sitting</span>
                    </b-dropdown-item> -->
                    <!-- <b-dropdown-item style="border-bottom: 1px solid #FFAD32;" @click="walkerSchedule(item.pet_id)">
                        <span class="text-center w-100" style="color:#1F9BCE">Walker Schedule</span>
                    </b-dropdown-item> -->
                </b-nav-item-dropdown>
            </div>
        </div>


        <!-- END PETS -->

        <!-- PETS -->
        <div class="position-relative mt-3 mb-2">
            <h1 class="text-white h3 text-center">Online Pets</h1>
        </div>

        <hr class=""/>

        <div class="position-relative mb-2 bg-white rounded py-1" v-if="online_pets.length < 1">
            <img src="@/static/undraw_friends_r511-removebg-preview.png" class="w-100" style="height:150px;object-fit: contain;" />
            <h1 class="text-dark h4 text-center">No Pet Available</h1>
        </div>

        <div class="d-flex align-items-center p-1 mt-1" style="gap:10px 20px;border: 1px solid #FFAD32;border-radius: 10px;"
            v-for="item in online_pets">
            <div>
                <img :src="item.pet.sha_picture"
                    class="rounded-circle" style="height:80px;width:80px" />
            </div>
            <router-link :to="`/shelter/adopt/detail/${item.sha_id}`">
                <h3 class="text-white h4 mb-0">{{item.pet.sha_name}}</h3>
                <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{item.pet.sha_species}}</h3>
                <h3 class="text-white h4 mb-0">{{ item.pet.sha_age }} Months, {{item.pet.sha_gender}}</h3>
            </router-link>
            <div class="ml-auto">
                <router-link :to="`/chats/${item.shelter.user_id}`">
                    <div class="text-white d-flex align-items-center"
                        style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                        <i class="bx bxs-message-dots" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                    </div>
                </router-link>
            </div>
        </div>

        <!-- END PETS -->


    </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'

import store from '@/store'
import Cookies from 'universal-cookie'
import Swal from 'sweetalert2'

let cookies = new Cookies()

export default {
    components: {
        BNavItemDropdown, BDropdownItem
    },
    methods: {
        editPet(id){
            this.$router.push('/profile/form-pet/'+id)
        },
        deletePet(id){
            Swal.fire({
                title: 'Do you want to delete this pet?',
                showCancelButton: true,
                confirmButtonText: 'Yes!',
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('pet/DeletePet', id).then(() => {
                        store.dispatch('pet/GetPetList', {
                            user_id: cookies.get('token')
                        })
                    })
                }
            })
        },
        walkerRegis(id){
            this.$router.push('/walker/'+id)
        },
        walkerSchedule(id){
            this.$router.push('/walker/schedule/'+id)
        }
    },
    mounted(){
        store.dispatch('pet/GetPetList', {
            user_id: cookies.get('token')
        })
    },
    computed: {
        user(){
            return store.state.auth.USER
        },
        pets(){
            return store.state.pet.PET
        },
        online_pets(){
            return this.user?.online_pets
        }
    },
    data(){
        return {
            default_img : 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>

<style>
    [dir] .b-nav-dropdown .dropdown-toggle::after {
        display: none;
    } 

    .dropdown-toggle, .dropdown {
        padding: 0;
        margin: 0;
    }

    .dropdown-menu {
        padding: .5em;
        border-radius: 10px;
    }
</style>